import { default as React, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { setI18nConfig } from 'res/languages';
import MasterRoutes from 'routing/MasterRoutes';
import RouteConfigs from 'routing/RouteConfigs';
import { humanDiffTimeLocale } from './utils/diffTimeLocale';

setI18nConfig();
humanDiffTimeLocale();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const App: React.FC<any> = () => {
    return (
        <Fragment>
            <Router>
                <MasterRoutes routes={RouteConfigs} />
            </Router>
        </Fragment>
    );
};

export default App;
