import i18n from 'i18n-js';
import memoize from 'lodash.memoize';
import vi from './translations/vi';

const translationGetters: any = {
    vi: () => vi
};

export const translate: any = memoize(
    (key: any, config?: any) => i18n.t(key, config),
    (key: any, config?: any) => (config ? key + JSON.stringify(config) : key)
);

export const setI18nConfig = (locale = 'vi'): void => {
    // clear translation cache
    console.log(locale, '1231');
    translate.cache.clear();

    // set i18n-js config
    i18n.translations = {
        [locale]: translationGetters[locale]()
    };
    i18n.locale = locale;
};

export const currentLocale = (): string => {
    return i18n.locale;
};

export const isEnglish = (): boolean => {
    return i18n.locale === 'en';
};
