export interface BaseEventType {
    type: EventBusName;
}

export interface BaseEventPayloadType<Payload> {
    type: EventBusName;
    payload: Payload;
}

export interface BaseEvent<Payload> {
    type: EventBusName;
    payload: Payload;
}

export enum EventBusName {
    LOGOUT_EVENT
}
