/**
 * Key of AsyncStorage
 */
export enum StorageKey {
    TOKEN = 'TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    USER = 'USER',
    OPEN_FIRST_TIME = 'OPEN_FIRST_TIME',
    RECENT_SEARCHES_PRODUCT = 'RECENT_SEARCHES_PRODUCT'
}

export class AsyncStorageUtils {
    /**
     * Lưu lại giá trị vào AsyncStorage
     * @param key
     * @param value
     */
    static save(key: StorageKey, value: string): void {
        localStorage.setItem(key, value);
    }

    /**
     * Lấy giá trị từ trong AsyncStorage
     * @param key
     */
    static get(key: StorageKey): string | null {
        return localStorage.getItem(key);
    }

    /**
     * Xóa giá trị đã lưu trong AsyncStorage
     * @param key
     */
    static remove(key: StorageKey): void {
        return localStorage.removeItem(key);
    }

    /**
     * Get đối tượng/mảng đã lưu từ AsyncStorage
     * @param key
     */
    static getObject<T>(key: StorageKey): T | null {
        const value = localStorage.getItem(key);
        if (!value) return null;

        return JSON.parse(value);
    }

    /**
     * Lưu lại đối tượng hoặc 1 mảng vào AsyncStorage
     * @param key
     * @param value
     */
    static saveObject<T>(key: StorageKey, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static clear(): void {
        localStorage.clear();
    }
}
