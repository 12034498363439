import {
    AppstoreOutlined,
    FileTextOutlined,
    FireOutlined,
    GiftOutlined,
    HomeOutlined,
    PercentageOutlined,
    PieChartOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    UpOutlined,
    UserOutlined
} from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const MainLayout = lazy(() => import('../libraries/layouts/main.layout'));
const ContentLayout = lazy(() => import('../libraries/layouts/content.layout'));
const AuthLayout = lazy(() => import('../libraries/layouts/auth.layout'));

const LoginScreen = lazy(() => import('../modulers/auth/login.screen'));

const ClientScreen = lazy(
    () => import('../modulers/account/client/client.screen')
);
const CustomerDetail = lazy(
    () => import('../modulers/account/client/client-detail.screen')
);
const StaffScreen = lazy(
    () => import('../modulers/account/staff/staff.screen')
);
const StaffDetail = lazy(
    () => import('../modulers/account/staff/staff-detail.screen')
);
const ChangePassword = lazy(
    () =>
        import('../modulers/account/staff/components/change-password.component')
);
const ChangePasswordClient = lazy(
    () =>
        import(
            '../modulers/account/client/components/change-password.component'
        )
);
const HomeScreen = lazy(() => import('../modulers/home/home.screen'));
const ProductScreen = lazy(() => import('../modulers/product/product.screen'));
const OrderScreen = lazy(() => import('../modulers/order/order.screen'));
const PromoScreen = lazy(() => import('../modulers/promo/promo.screen'));
const NewsScreen = lazy(() => import('../modulers/news/news.screen'));
const NewsDetailScreen = lazy(
    () => import('../modulers/news/news-detail.screen')
);
const AnalystOverviewScreen = lazy(
    () => import('../modulers/analyst/analyst-overview/analyst-overview.screen')
);
const ChatScreen = lazy(() => import('../modulers/chat/chat.screen'));

const AnalystCompareScreen = lazy(
    () => import('../modulers/analyst/analyst-compare.screen')
);
const AnalystChartScreen = lazy(
    () => import('../modulers/analyst/analyst-chart/analyst-chart.screen')
);
const CategoryScreen = lazy(
    () => import('../modulers/category/category.screen')
);
const FlashDealScreen = lazy(
    () => import('../modulers/flash-deal/flash-deal.screen')
);
const ForgotPasswordScreen = lazy(
    () => import('../modulers/auth/forgot-password.screen')
);
const OtpScreen = lazy(() => import('../modulers/auth/otp.screen'));
const ResetPassWordScreen = lazy(
    () => import('../modulers/auth/reset-password.screen')
);
const CategoryNewsScreen = lazy(
    () => import('../modulers/category/news/category-news.screen')
);
const CategoryNewsDetailScreen = lazy(
    () => import('../modulers/category/news/category-news-detail.screen')
);
const CategoryProductScreen = lazy(
    () => import('../modulers/category/product/product.screen')
);
const CategoryProductDetailScreen = lazy(
    () => import('../modulers/category/product/category-product-detail.screen')
);
const NewsCustomerViewScreen = lazy(
    () => import('../modulers/news/news-customer-view.screen')
);

//create Product
const ProductCreate = lazy(
    () => import('../modulers/product/client/product.client.create')
);

const OrderDetail = lazy(() => import('../modulers/order/order-detail.screen'));

const SettingContact = lazy(
    () => import('../modulers/settings/settings.contact.screen')
);

const NotificationScreen = lazy(
    () => import('../modulers/notification/notification.screen')
);

const WarehouseScreenDetail = lazy(
    () => import('../modulers/warehouse/warehouse-detail.screen')
);

const RankingScreen = lazy(() => import('../modulers/ranking/ranking.screen'));
const RankingV2Screen = lazy(
    () => import('../modulers/ranking-v2/ranking-v2.screen')
);

const RankingV2AddScreen = lazy(
    () => import('../modulers/ranking-v2/ranking-v2-add.screen')
);

const RankDetailScreen = lazy(
    () => import('../modulers/ranking/rank-detail.screen')
);
const PartnerRequestScreen = lazy(
    () => import('../modulers/ranking/partner-request.screen')
);

const SettingBank = lazy(
    () => import('../modulers/settings/setting.bank.screen')
);
const CreateBank = lazy(
    () => import('../modulers/settings/component/settings.bank.create')
);
const SettingPolicy = lazy(
    () => import('../modulers/settings/setting.policy.screen')
);

const flashDealDetail = lazy(
    () => import('../modulers/flash-deal/flash-deal-detail.screen')
);

const PromoCreate = lazy(() => import('../modulers/promo/promo.create.screen'));
const ListBanner = lazy(
    () => import('../modulers/display/banner/banner.screen')
);

const BannerDetailScreen = lazy(
    () => import('../modulers/display/banner/banner-detail.screen')
);

const SplashScreen = lazy(
    () => import('../modulers/display/splash/splash.screen')
);

const ProductDetail = lazy(
    () => import('../modulers/product/product-detail.screen')
);
const ProductCustomerView = lazy(
    () => import('../modulers/product/product-customer-view.screen')
);

const AnalystChartOrderScreen = lazy(
    () => import('../modulers/analyst/analyst-chart/analyst-chart-order.screen')
);
const AnalystChartProductScreen = lazy(
    () =>
        import('../modulers/analyst/analyst-chart/analyst-chart-product.screen')
);

const OtherSettingScreen = lazy(
    () => import('../modulers/settings/setting.other.screen')
);

const ShippingScreen = lazy(
    () => import('../modulers/settings/setting.shipping.screen')
);

const ApprovedPartnerScreen = lazy(
    () => import('../modulers/ranking/approved-partner.screen')
);

const TransactionScreen = lazy(
    () => import('../modulers/ranking/transaction.screen')
);

const QnAScreen = lazy(() => import('../modulers/qna/qna.screen'));

const ContactFormScreen = lazy(
    () => import('../modulers/contact-form/contact-form.screen')
);
const AboutUsScreen = lazy(
    () => import('../modulers/about-us/about-us.screen')
);
const RecruitmentScreen = lazy(
    () => import('../modulers/recruitment/recruitment.screen')
);

const NewsletterScreen = lazy(
    () => import('../modulers/newsletters/newsletter.screen')
);

const CommissionScreen = lazy(
    () => import('../modulers/commission/commission.screen')
);

const WarehouseScreen = lazy(
    () => import('../modulers/warehouse/warehouse.screen')
);

const NotificationAddScreen = lazy(
    () => import('../modulers/notification/notification-add.screen')
);

// const CommissionDetailScreen = lazy(
//     () => import('../modulers/commission/commission-detail.screen')
// );

const RouteConfigs: IRoute[] = [
    {
        path: '/auth',
        name: 'auth',
        component: AuthLayout,
        hideInMenu: true,
        routes: [
            {
                path: '/auth/login',
                name: 'login',
                component: LoginScreen,
                exact: true
            },
            {
                path: '/auth/forgot-password',
                name: 'forgot_password',
                component: ForgotPasswordScreen,
                exact: true
            },
            {
                path: '/auth/otp',
                name: 'otp',
                component: OtpScreen,
                exact: true
            },
            {
                path: '/auth/reset-password',
                name: 'reset_password',
                component: ResetPassWordScreen,
                exact: true
            }
        ]
    },
    {
        path: '/',
        name: 'menu_home',
        component: MainLayout,
        exact: false,
        routes: [
            {
                path: '/',
                name: 'menu_home',
                icon: <HomeOutlined />,
                exact: true,
                component: AnalystOverviewScreen
            },
            {
                path: '/user',
                name: 'menu_account',
                component: ContentLayout,
                existSubMenu: true,
                icon: <UserOutlined />,
                routes: [
                    {
                        path: '/user/staff',
                        name: 'menu_staff',
                        component: StaffScreen,
                        exact: true
                    },
                    {
                        path: '/user/add/staff',
                        name: 'menu_add_staff',
                        component: StaffDetail,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/user/edit/staff/:id',
                        name: 'menu_edit_staff',
                        component: StaffDetail,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/user/change-password/staff/:id',
                        name: 'menu_password_staff',
                        component: ChangePassword,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/user/client',
                        name: 'menu_client',
                        component: ClientScreen,
                        exact: true
                    },
                    {
                        path: '/user/add/client',
                        name: 'menu_add_client',
                        component: CustomerDetail,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/user/detail/client/:id',
                        name: 'menu_detail_client',
                        component: CustomerDetail,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/user/change-password/client/:id',
                        name: 'menu_password_client',
                        component: ChangePasswordClient,
                        exact: true,
                        hideInMenu: true
                    }
                ]
            },
            {
                path: '/products',
                name: 'menu_product',
                icon: <ShopOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/products',
                        name: 'menu_product',
                        component: ProductScreen,
                        exact: true
                    },
                    {
                        path: '/products/product-create',
                        name: 'product_add',
                        component: ProductCreate,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/products/product-edit/:id',
                        name: 'product_edit',
                        component: ProductCreate,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/products/product-detail/:id',
                        name: 'product_detail',
                        component: ProductDetail,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/products/product-view/:id',
                        name: 'product_view',
                        component: ProductCustomerView,
                        hideInMenu: true,
                        exact: true
                    }
                ]
            },
            {
                path: '/orders',
                name: 'menu_order',
                icon: <ShoppingCartOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/orders',
                        name: 'menu_order',
                        component: OrderScreen,
                        exact: true
                    },
                    {
                        path: '/orders/detail/:id',
                        name: 'menu_order_detail',
                        component: OrderDetail,
                        hideInMenu: true,
                        exact: true
                    }
                ]
            },
            {
                path: '/commission',
                name: 'menu_commission',
                icon: <PercentageOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/commission',
                        name: 'menu_commission',
                        component: CommissionScreen,
                        exact: true
                    }
                    // {
                    //     path: '/commission/add',
                    //     name: 'menu_commission_add',
                    //     component: CommissionDetailScreen,
                    //     hideInMenu: true,
                    //     exact: true
                    // },
                    // {
                    //     path: '/commission/detail/:id',
                    //     name: 'menu_commission_detail',
                    //     component: CommissionDetailScreen,
                    //     hideInMenu: true,
                    //     exact: true
                    // }
                ]
            },
            // {
            //     path: '/chat',
            //     name: 'menu_chat',
            //     icon: <MessageOutlined />,
            //     exact: true,
            //     component: ChatScreen
            // },
            // {
            //     path: '/analysts',
            //     name: 'menu_analysts',
            //     existSubMenu: true,
            //     icon: <PieChartOutlined />,
            //     component: ContentLayout,
            //     routes: [
            //         {
            //             path: '/analysts/overview',
            //             name: 'menu_overview_analysts',
            //             component: AnalystOverviewScreen,
            //             exact: true,
            //             hideInMenu: true
            //         },
            //         {
            //             path: '/analysts/top-chart',
            //             name: 'menu_top_chart',
            //             component: AnalystChartScreen,
            //             exact: true
            //         },
            //         {
            //             path: '/analysts/top-chart-order',
            //             name: 'menu_top_chart',
            //             component: AnalystChartOrderScreen,
            //             hideInMenu: true,
            //             exact: true
            //         },
            //         {
            //             path: '/analysts/top-chart-product',
            //             name: 'menu_top_chart',
            //             component: AnalystChartProductScreen,
            //             hideInMenu: true,
            //             exact: true
            //         },
            //         {
            //             path: '/analysts/compare',
            //             name: 'menu_compare',
            //             component: AnalystCompareScreen,
            //             exact: true,
            //             hideInMenu: true
            //         }
            //     ]
            // },
            {
                path: '/promos',
                name: 'menu_promo',
                icon: <GiftOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/promos',
                        name: 'create_promo',
                        component: PromoScreen,
                        exact: true
                    },
                    {
                        path: '/promos/promo-create',
                        name: 'create_promo',
                        component: PromoCreate,
                        exact: true
                    },
                    {
                        path: '/promos/promo-edit/:id',
                        name: 'edit_promo',
                        component: PromoCreate,
                        exact: true
                    }
                ]
            },
            {
                path: '/flash-deal',
                name: 'menu_flashdeal',
                icon: <FireOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/flash-deal',
                        name: 'menu_flashdeal',
                        component: FlashDealScreen,
                        exact: true
                    },
                    {
                        path: '/flash-deal/add',
                        name: 'menu_flashdeal_add',
                        component: flashDealDetail,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/flash-deal/edit/:id',
                        name: 'menu_flashdeal_edit',
                        component: flashDealDetail,
                        hideInMenu: true,
                        exact: true
                    }
                ]
            },
            {
                path: '/newses',
                name: 'menu_news',
                icon: <FileTextOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/newses',
                        name: 'menu_news',
                        component: NewsScreen,
                        exact: true
                    },
                    {
                        path: '/newses/add',
                        name: 'news_add',
                        component: NewsDetailScreen,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/newses/edit/:id',
                        name: 'news_edit',
                        component: NewsDetailScreen,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/newses/customer_view/:id',
                        name: 'customer_view_edit',
                        component: NewsCustomerViewScreen,
                        hideInMenu: true,
                        exact: true
                    }
                ]
            },
            // {
            //     path: '/contact-form',
            //     name: 'menu_contact_form',
            //     icon: <ContactsOutlined />,
            //     component: ContactFormScreen
            // },
            // {
            //     path: '/newsletter',
            //     name: 'menu_newsletter',
            //     icon: <RedEnvelopeOutlined />,
            //     component: NewsletterScreen
            // },
            {
                path: '/categories',
                name: 'menu_category',
                existSubMenu: true,
                icon: <PieChartOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/categories/product/list',
                        name: 'product_category',
                        component: CategoryProductScreen,
                        exact: true
                    },
                    {
                        path: '/categories/product/add',
                        name: 'product_category_add',
                        component: CategoryProductDetailScreen,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/categories/product/edit/:id',
                        name: 'product_category_edit',
                        component: CategoryProductDetailScreen,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/categories/news/list',
                        name: 'news_category',
                        component: CategoryNewsScreen,
                        exact: true
                    },
                    {
                        path: '/categories/news/add',
                        name: 'news_category_add',
                        component: CategoryNewsDetailScreen,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/categories/news/edit/:id',
                        name: 'news_category_edit',
                        component: CategoryNewsDetailScreen,
                        hideInMenu: true,
                        exact: true
                    }
                ]
            },
            {
                path: '/display',
                name: 'banner.layout',
                existSubMenu: true,
                icon: <AppstoreOutlined />,
                component: ContentLayout,
                routes: [
                    // {
                    //     path: '/display/splash',
                    //     name: 'splash.title',
                    //     component: SplashScreen,
                    //     exact: true
                    // },
                    {
                        path: '/display/banner',
                        name: 'banner.title',
                        component: ListBanner,
                        exact: true
                    },
                    {
                        path: '/display/banner/add',
                        name: 'banner.add_banner',
                        component: BannerDetailScreen,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/display/banner/edit/:id',
                        name: 'edit_banner',
                        component: BannerDetailScreen,
                        exact: true,
                        hideInMenu: true
                    }
                ]
            },
            {
                path: '/ranking',
                name: 'menu_ranking',
                existSubMenu: true,
                icon: <UpOutlined />,
                component: ContentLayout,
                routes: [
                    // {
                    //     path: '/ranking/requests',
                    //     name: 'menu_partner_request',
                    //     component: PartnerRequestScreen,
                    //     exact: true
                    // },
                    // {
                    //     path: '/ranking/transaction',
                    //     name: 'menu_pay_transaction',
                    //     component: TransactionScreen,
                    //     exact: true
                    // },
                    // {
                    //     path: '/ranking/approved',
                    //     name: 'menu_approved_partner',
                    //     component: ApprovedPartnerScreen,
                    //     exact: true
                    // },
                    {
                        path: '/ranking/configs',
                        name: 'menu_rank_config',
                        component: RankingScreen,
                        exact: true
                    },
                    {
                        path: '/ranking/configs_v2',
                        name: 'menu_rank_config_v2',
                        component: RankingV2Screen,
                        exact: true
                    },
                    {
                        path: '/ranking/configs/add',
                        name: 'menu_rank_config_add',
                        component: RankingV2AddScreen,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/ranking/configs/:id',
                        name: 'menu_rank_config_add',
                        component: RankingV2AddScreen,
                        exact: true,
                        hideInMenu: true
                    },
                    {
                        path: '/ranking/:id',
                        name: 'menu_rank_detail',
                        component: RankDetailScreen,
                        exact: true,
                        hideInMenu: true
                    }
                ]
            },
            {
                path: '/settings',
                name: 'menu_settings',
                existSubMenu: true,
                icon: <SettingOutlined />,
                component: ContentLayout,
                routes: [
                    {
                        path: '/settings/notification',
                        name: 'menu_settings_notification',
                        component: NotificationScreen,
                        exact: true
                    },
                    {
                        path: '/settings/notification/add',
                        name: 'menu_settings_notification',
                        component: NotificationAddScreen,
                        exact: false,
                        hideInMenu: true
                    },
                    {
                        path: '/settings/notification/edit/:id',
                        name: 'menu_settings_notification',
                        component: NotificationAddScreen,
                        exact: false,
                        hideInMenu: true
                    },
                    {
                        path: '/settings/warehouse',
                        name: 'menu_settings_warehouse',
                        component: WarehouseScreen,
                        exact: true
                    },
                    {
                        path: '/settings/warehouse/edit/:id',
                        name: 'menu_settings_warehouse_edit',
                        hideInMenu: true,
                        component: WarehouseScreenDetail,
                        exact: false
                    },
                    {
                        path: '/settings/warehouse/add',
                        name: 'menu_settings_warehouse_add',
                        hideInMenu: true,
                        component: WarehouseScreenDetail,
                        exact: false
                    },
                    // {
                    //     path: '/settings/shipping',
                    //     name: 'menu_settings_shipping',
                    //     component: ShippingScreen,
                    //     exact: true
                    // },
                    {
                        path: '/settings/settings-contact',
                        name: 'menu_settings_info',
                        component: SettingContact,
                        exact: true
                    },
                    {
                        path: '/settings/settings-bank-info',
                        name: 'menu_settings_bank_info',
                        component: SettingBank,
                        exact: true
                    },
                    {
                        path: '/settings/settings-bank/create',
                        name: 'add_bank_info',
                        component: CreateBank,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/settings/settings-bank/edit/:id',
                        name: 'edit_bank_info',
                        component: CreateBank,
                        hideInMenu: true,
                        exact: true
                    },
                    {
                        path: '/settings/settings-policy',
                        name: 'usage_policy',
                        component: SettingPolicy,
                        exact: true
                    },
                    {
                        path: '/settings/setting-other',
                        name: 'menu_setting_other',
                        component: OtherSettingScreen,
                        exact: true
                    },
                    {
                        path: '/settings/qna',
                        name: 'menu_setting_qna',
                        component: QnAScreen,
                        exact: true
                    },
                    {
                        path: '/settings/about-us',
                        name: 'menu_about_us',
                        component: AboutUsScreen,
                        exact: true
                    }
                    // {
                    //     path: '/settings/recruitment',
                    //     name: 'menu_recruitment',
                    //     component: RecruitmentScreen,
                    //     exact: true
                    // }
                ]
            }
        ]
    }
];

export default RouteConfigs;
