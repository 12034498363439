export default {
    ...require('./vi.shared.json'),
    ...require('../../modulers/account/res/translations/vi.account.json'),
    ...require('../../modulers/analyst/res/translations/vi.analyst.json'),
    ...require('../../modulers/auth/res/translations/vi.auth.json'),
    ...require('../../modulers/category/res/translations/vi.category.json'),
    ...require('../../modulers/flash-deal/res/translations/vi.flash-deal.json'),
    ...require('../../modulers/home/res/translations/vi.home.json'),
    ...require('../../modulers/news/res/translations/vi.news.json'),
    ...require('../../modulers/order/res/translations/vi.order.json'),
    ...require('../../modulers/product/res/translations/vi.product.json'),
    ...require('../../modulers/promo/res/translations/vi.promo.json'),
    ...require('../../modulers/settings/res/translations/vi.settings.json'),
    ...require('../../modulers/display/banner/res/translations/vi.banner.json'),
    ...require('../../modulers/display/splash/res/translations/vi.splash.json'),
    ...require('../../modulers/warehouse/res/translations/vi.warehouse.json'),
    ...require('../../modulers/ranking/res/translations/vi.rank.json'),
    ...require('../../modulers/qna/res/translations/vi.qna.json'),
    ...require('../../modulers/recruitment/res/translations/vi.recruitment.json'),
    ...require('../../modulers/ranking-v2/res/translations/vi.rank-v2.json'),
    ...require('../../modulers/commission/res/translations/vi.commission.json'),
};
